
export default {
  name: 'OrganismContactForm',
  props: {
    disableCompanyNumber: {
      required: false,
      type: Boolean,
      default: false
    },
    disableCompanyAddress: {
      required: false,
      type: Boolean,
      default: false
    },
    titleContactForm: {
      required: false,
      type: String,
      default: "Leave us a message"
    },
    subtitleContactForm: {
      required: false,
      type: String,
      default: "Leave us a message and will get back to you as soon as possible"
    }
  },
}
